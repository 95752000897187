import React from "react";

import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';

import './center_text_section.css';


const CenterTextSection = (props) => {
    const {
        title,
        subtitle,
        children,
    } = props;


    return (
        <Container>
            <Row>
                <Col xs={12} md={{ offset: 2, span: 8 }}>
                    <div className="center-text-section-container">
                        <h3 className="center-text-section-title">
                            { title }
                        </h3>
                        <p className="center-text-section-subtitle">
                            { subtitle }
                        </p>
                        { children }
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default CenterTextSection;
