import React, { useState } from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";

import PasswordView from './views/password';
import LandingView from './views/landing';


function App() {
    const [authenticated, setAuthenticated] = useState(false);

    const onAuthenticated = (status) => {
        setAuthenticated(status);
    }

    if (!authenticated) {
        return <PasswordView onAuthenticated={onAuthenticated} />
    }

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={
                        <LandingView />
                    } />
                    <Route path="*" element={
                        <Navigate replace to="/" />
                    } />
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;