import React from "react";

import {
    Container,
} from 'react-bootstrap';

import './service_steps_section.css';

const ServiceStepsSection = (props) => {
    const {
        title,
        steps,
        children,
    } = props;

    const renderStep = (step) => {
        const { title, description } = step;

        return (
            <li key={title}>
                <br/>{title}
                <p>
                    {description}
                </p>
            </li>
        );
    };

    return (
        <div className="service-steps-container">
            <Container>
                { title }
                <ul>
                    { steps.map(renderStep) }
                </ul>
                { children }
            </Container>
        </div>
    );
};

export default ServiceStepsSection;
