import Britax01 from '../assets/img/coches/britax_01.png';
import Britax02 from '../assets/img/coches/britax_02.png';
import BritaxLogo from '../assets/img/coches/britax_logo.png';

import Amount01 from '../assets/img/amounts/amount_1.svg';
import Amount02 from '../assets/img/amounts/amount_2.svg';
import Amount03 from '../assets/img/amounts/amount_3.svg';
import Amount04 from '../assets/img/amounts/amount_4.svg';

import TrashCan from '../assets/img/additionals/trash_can.png';
import WashingMachine from '../assets/img/additionals/washing_machine.png';
// import Certificate from '../assets/img/additionals/certificate.png';
import GarbageBag from '../assets/img/additionals/garbage_bag.png';
import ProductHipoglos from '../assets/img/additionals/product_hipoglos.jpeg';
import ProductCotton from '../assets/img/additionals/product_cotton.jpeg';
import ProductTalcum from '../assets/img/additionals/product_talcum.jpeg';
import ProductWhipes from '../assets/img/additionals/product_whipes.png';
import ProductVaseline from '../assets/img/additionals/product_vaseline.png';
import ProductAlcohol from '../assets/img/additionals/product_alcohol.png';

const PAGE_SCHEMA = [
    {
        title: 'Inicio',
        description: ''
    },
    {
        title: 'Coches',
        description: 'Elige talla, marca y cantidad mensual. Podrás actualizar tu pack de manera muy simple una vez que tu hijo crezca.',
        footnote: 'Con nosotros te puedes relajar 😉 antes de cada envío recibirás un correo donde podrás actualizar los productos de tu suscripción.',
        fields: [
            {
                id: '1',
                title: 'Modelo',
                description: 'Te orientaremos en la talla ideal a medida que crezca tu guagua',
                options: [
                    {
                        id: '101',
                        title: 'Travel system',
                        subtitle: '3 en 1, coche, porta auto y guevito',
                        src: Britax01,
                    },
                    {
                        id: '102',
                        title: 'Coche',
                        src: Britax02,
                    },
                ],
            },
            {
                id: '2',
                title: 'Marca',
                description: 'La primera vez que recibas tu suscripción, te enviaremos un pañal de cada marca para que puedas elegir la que mejor se ajuste a tu guagua',
                options: [
                    {
                        id: '201',
                        title: 'Britax',
                        src: BritaxLogo,
                    },
                ],
            },
            {
                id: '3',
                title: 'Cantidad Mensual',
                options: [
                    {
                        id: '301',
                        title: '225 pañales',
                        subtitle: '7.5 pañales diarios',
                        src: Amount04,
                    },
                    {
                        id: '302',
                        title: '180 pañales',
                        subtitle: '6 pañales diarios',
                        src: Amount03,
                    },
                    {
                        id: '303',
                        title: '156 pañales',
                        subtitle: '5.2 pañales diarios',
                        src: Amount02,
                    },
                    {
                        id: '304',
                        title: '150 pañales',
                        subtitle: '5 pañales diarios',
                        src: Amount01,
                    },
                ],
            },
        ],
    },
    {
        title: 'Complementos',
        description: 'Elige de todo para tu guagua para que ningún cambio de pañal te pille volando bajo.',
        footnote: 'Con nosotros te puedes relajar 😉 antes de cada envío recibirás un correo donde podrás actualizar los productos de tu suscripción.',
        fields: [
            {
                id: '1',
                title: 'Complementos',
                description: 'Que ningún cambio de pañal te pille sin...',
                multiple: true,
                options: [
                    {
                        id: '101',
                        title: 'Pomada para coceduras 100g',
                        subtitle: 'D\'Hipolgós',
                        src: ProductHipoglos,
                    },
                    {
                        id: '102',
                        title: 'Algodón zig-zag 100g',
                        subtitle: 'Family Care',
                        src: ProductCotton,
                    },
                    {
                        id: '103',
                        title: 'Talco para bebés 200g',
                        subtitle: 'Babyland',
                        src: ProductTalcum,
                    },
                    {
                        id: '104',
                        title: 'Toallitas húmedas 80un',
                        subtitle: 'Emuwhipes',
                        src: ProductWhipes,
                    },
                    {
                        id: '105',
                        title: 'Vaselina sin perfume 110ml',
                        subtitle: 'Simond\'s',
                        src: ProductVaseline,
                    },
                    {
                        id: '106',
                        title: 'Alcohol 70% 1L',
                        subtitle: 'Difem Pharma',
                        src: ProductAlcohol,
                    }
                ],
            },
        ],
    },
    {
        title: 'Residuos',
        description: 'Selecciona el método que más te acomode para desechar los pañales usados.',
        footnote: 'Con nosotros te puedes relajar 😉 antes de cada envío recibirás un correo donde podrás actualizar los productos de tu suscripción.',
        fields: [
            {
                id: '1',
                title: 'Residuos',
                description: 'Sabemos que huelen, queremos ayudarte con eso también',
                multiple: true,
                options: [
                    {
                        id: '101',
                        title: 'Basurero anti-olor',
                        subtitle: 'Arriendo mensual',
                        src: TrashCan,
                        selected: true,
                    },
                    {
                        id: '102',
                        title: 'Bolsas de basura',
                        subtitle: 'Recarga para un mes',
                        src: GarbageBag,
                        selected: true,
                    },
                    {
                        id: '103',
                        title: 'Lavandería',
                        subtitle: 'Para pañales reutilizables',
                        label: 'PRONTO',
                        disabled: true,
                        src: WashingMachine,
                    },
                ],
            },
            // {
            //     id: '2',
            //     title: 'Ayudemos a ayudar',
            //     description: 'Cada vez que recibas un pack, donaremos a alguna institución de niños la cantidad de pañales que indiques',
            //     options: [
            //         {
            //             id: '201',
            //             title: '15 pañales al mes',
            //             label: 'PRONTO',
            //             disabled: true,
            //             src: DiaperPR,
            //         },
            //     ],
            // },
            // {
            //     id: '3',
            //     title: 'Huella de Carbono',
            //     description: 'Sabemos que es difícil reemplazar los pañales más contaminantes, pero puedes ayudar al planeta compensando su impacto.',
            //     options: [
            //         {
            //             id: '301',
            //             title: 'Bonos de carbono',
            //             label: 'PRONTO',
            //             disabled: true,
            //             src: Certificate,
            //         },
            //     ],
            // },
        ],
    },
    {
        title: 'Agendar',
        description: 'Verifica que todo esté en orden y nosotros te enviaremos mensualmente tu pack.'
    },
];

export default PAGE_SCHEMA;
