import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

import HeroSection from '../sections/hero_section_2';
import ServiceStepsSection from '../sections/service_steps_section';
import ImageSection from '../sections/image_section';

import TopBar from '../components/top_bar';
import ProgressBar from '../components/progress_bar';
import PrimaryButton from '../components/primary_button';
import CardFormSection from '../sections/card_form_section';
import ReviewSection from '../sections/review_section';
import FooterSection from '../sections/footer_section';
import CenterTextSection from '../sections/center_text_section';

import HeroBackground from '../assets/img/hero_background.jpeg';
import LogoHorizontal from '../assets/img/logos/logo_horizontal.svg';
import ProductTrashCan from '../assets/img/additionals/product_trash_can.png';

import PAGE_SCHEMA from './page_schema.js';

import './landing.css';

const LandingView = () => {
    const [ currentStep, setCurrentStep ] = useState(0);
    const [ nextVisible, setNextVisible ] = useState(true);

    const [ steps, setSteps ] = useState(PAGE_SCHEMA);

    const serviceSteps = [...steps];
    delete serviceSteps[0];

    useEffect(() => {
        if (currentStep + 1 < steps.length) {
            setNextVisible(true);
        } else {
            setNextVisible(false);
        }
    }, [currentStep, steps]);

    useEffect(() => {
        window.scroll({ top: 0 });
        document.title = `Tooto - ${steps[currentStep].title}`;
    }, [currentStep]);

    const handleFieldClick = ({ fieldId, optionId }) => {
        const setStepsUpdate = [...steps];

        setStepsUpdate[currentStep].fields.forEach((field) => {
            if (field.id !== fieldId) return;

            field.options.forEach((option) => {
                if (option.id === optionId) {
                    option.selected = !option.selected;
                } else if (!field.multiple) {
                    option.selected = false;
                }
            });
        });

        setSteps(setStepsUpdate);
    }

    const getSubscriptionItems = () => {
        const selectedItems = [];

        steps.forEach((section) => {
            const { fields } = section;

            if (!fields) return;

            fields.forEach((field) => {
                const { title: fieldTitle, options } = field;

                if (!options) return;

                options.forEach((option) => {
                    const { title: optionTitle, subtitle, selected, src } = option;

                    if (!selected) return;

                    const title = `${fieldTitle}: ${optionTitle}`;

                    selectedItems.push({ title, subtitle, src });
                });
            });
        });

        return selectedItems;
    }

    return (
        <div>
            <TopBar
                brandLogo={LogoHorizontal}
            />
            {
                currentStep === 0 && (
                    <HeroSection
                        title={<span>¡No compres un coche!<br />Mejor suscríbete a dos ;)</span>}
                        subtitle="Diseña tu pack ideal y suscríbete con opción de compra"
                        src={HeroBackground}
                    >
                        <PrimaryButton
                            onClick={() => setCurrentStep(1)}
                        >
                            Armar mi pack
                        </PrimaryButton>
                    </HeroSection>
                )
            }
            {
                currentStep === 0 && (
                    <>
                        <CenterTextSection
                            title={<h3>¿Qué es <span style={{ color: '#f15a24' }}>tooto</span>?</h3>}
                            subtitle="Ofrecemos un servicio de suscripción para simplificarte la pega de cambiar pañales. Nosotros nos encargamos de que todos los meses recibas todo lo necesario para tu guagua."
                        />
                        <ServiceStepsSection
                            title={<h3>¿Cómo funciona?</h3>}
                            steps={serviceSteps}
                        >
                            <Row>
                                <Col xs={12} md={{ offset: 4, span: 4 }}>
                                    <br/>
                                    <br/>
                                    <PrimaryButton
                                        block
                                        onClick={() => setCurrentStep(1)}
                                    >
                                        Armar mi pack
                                    </PrimaryButton>
                                </Col>
                            </Row>
                        </ServiceStepsSection>

                        <ImageSection
                            src={ProductTrashCan}
                            backgroundSize="contain"
                            alignment="right"
                        >
                            <h3>
                                Cada <span style={{ color: '#f15a24' }}>mes</span> te ahorras el olor
                            </h3>
                            <p style={{ fontSize: '1rem', fontWeight: 300 }}>
                                Inserta, gira, cierra y el pañal quedará sellado, evitando fugas de olor.
                                <br/>
                                <br/>
                                Desecha los pañales sucios de forma rápida, sencilla y segura, con una protección garantizada contra el mal olor y los gérmenes.
                                <br/>
                                <br/>
                                <strong>Características</strong>
                                <br/>
                                <br/>
                                <ul>
                                    <li>Capacidad para 28 pañales de talla P</li><br/>
                                    <li>Tamaño compacto</li><br/>
                                    <li>Hecho en un 98% con plástico reciclado</li><br/>
                                    <li>Suscríbete sólo los meses que lo necesites</li>
                                </ul>
                                <br/>
                                <PrimaryButton
                                    onClick={() => setCurrentStep(1)}
                                >
                                    Armar mi pack
                                </PrimaryButton>
                            </p>
                        </ImageSection>
                        <FooterSection />
                    </>
                )
            }
            {
                (currentStep >= 1 && currentStep < 4) && (
                    <CardFormSection
                        fields={steps[currentStep].fields}
                        footnote={steps[currentStep].footnote}
                        onClick={handleFieldClick}
                    />
                )
            }
            {
                currentStep === 4 && (
                    <ReviewSection
                        items={getSubscriptionItems()}
                    />
                )
            }
            {
                currentStep > 0 && (
                    <ProgressBar
                        steps={steps}
                        currentStep={currentStep}
                        onStepChange={setCurrentStep}
                        nextVisible={nextVisible}
                    />
                )
            }
        </div>
    );
};

export default LandingView;
