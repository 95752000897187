import React from "react";
import {
    Container,
} from 'react-bootstrap';

import './top_bar.css';

const TopBar = (props) => {
    const {
        brandLogo,
    } = props;

    return (
        <div className="top-bar">
            <Container>
                <img className="top-bar-logo" src={brandLogo} alt="brand logo" />
            </Container>
        </div>
    );
};

export default TopBar;
