import _ from 'lodash';
import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';
import React, { useState, useEffect } from 'react';

import './password.css';

import PrimaryButton from '../components/primary_button';
import Logo from '../assets/img/logos/logo_vertical.svg';

const { REACT_APP_PASSWORD } = process.env;

const PasswordView = (props) => {
    const {
        onAuthenticated,
    } = props;

    const [password, setPassword] = useState();
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        const authenticated = _.isEmpty(REACT_APP_PASSWORD);
        
        onAuthenticated(authenticated);
    }, [REACT_APP_PASSWORD]);

    useEffect(() => {
        setSubmitDisabled(password === undefined || password.length === 0);
    }, [password]);

    const handlePasswordInput = (event) => {
        const { value } = event.target;

        setPassword(value);
    }

    const handleSubmit = () => {
        const authenticated = REACT_APP_PASSWORD === password;
        
        onAuthenticated(authenticated);
        setSubmitted(true);
    }

    const handleInputKeyDown = (event) => {
        const { key } = event;
        
        if (key === 'Enter' && !submitDisabled) {
            handleSubmit();
        }
    }

    return (
        <Container>
            <Row>
                <Col xs={12} md={{ span: 6, offset: 3 }}>
                    <div className="password-introduction-container">
                        <img src={Logo} alt="tooto logo" />
                        <h3>
                            Por ahora necesitas una invitación
                        </h3>
                        <p>
                            ¡Para estar al tanto con las novedades, síguenos en Instagram @tooto.cl!
                        </p>
                        <br />
                        <br />
                        <input
                            className="form-control mb-4"
                            type="password"
                            onChange={handlePasswordInput}
                            onKeyDown={handleInputKeyDown}
                            placeholder="Código de invitación"
                        />

                        {
                            submitted && <p className="form-error">Tu código no es válido</p>
                        }

                        <PrimaryButton
                            type="submit"
                            onClick={handleSubmit}
                            disabled={submitDisabled}
                        >
                            Armar mi pack
                        </PrimaryButton>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default PasswordView;
