import React from "react";
import PropTypes from "prop-types";
import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';

import './card_form_section.css';

const CardFormSection = (props) => {
    const {
        fields,
        footnote,
        onClick,
    } = props;

    const renderFieldOption = (fieldId, option) => {
        const { id, title, subtitle, src, selected, disabled, label } = option;

        let className = 'card-form-section-button';
        if (selected) {
            className += ' selected';
        }

        return (
            <Col key={id} xs={6} md={6} className="card-form-section-col">
                <button
                    id={id}
                    className={className}
                    onClick={() => onClick({ fieldId, optionId: id })}
                    disabled={disabled}
                >
                    {
                        label && (
                            <span className="card-form-section-col-label">
                                { label }
                            </span>
                        )
                    }
                    <img src={src} alt={title} />
                    <p className="card-form-section-col-title">
                        {title}
                    </p>
                    <p className="card-form-section-col-subtitle">
                        { subtitle }
                    </p>
                </button>
            </Col>
        );
    };

    const renderField = (field) => {
        const { id, title, description, options } = field;

        return (
            <Row key={title} className="card-form-section-row">
                <Col xs={12} className="card-form-section-title">
                    <h1 style={{ textAlign: 'center' }}>
                        { title }
                    </h1>
                    {
                        description && (
                            <p>
                                { description }
                            </p>
                        )
                    }
                </Col>
                { options.map((option) => renderFieldOption(id, option)) }
            </Row>
        );
    };

    return (
        <Container className="card-form-section-container">
            <Row>
                <Col xs={12} md={{ span: 6, offset: 3}}>
                    {
                        footnote && (
                            <p className="card-form-section-footnote">
                                { footnote }
                            </p>
                        )
                    }
                    { fields.map(renderField) }
                </Col>
            </Row>
        </Container>
    );
};

CardFormSection.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        multiple: PropTypes.bool,
        options: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            subtitle: PropTypes.string,
            src: PropTypes.string,
            selected: PropTypes.bool,
            disabled: PropTypes.bool,
        })),
    })),
    onClick: PropTypes.func,
};

CardFormSection.defaultProps = {
    fields: [],
    onClick: () => {},
};

export default CardFormSection;
