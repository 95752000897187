import React from "react";
import PropTypes from 'prop-types';
import _ from "lodash";
import {
    Row,
    Col,
} from 'react-bootstrap';

import PrimaryButton from "./primary_button";
import SecondaryButton from "./secondary_button";

import "./progress_bar.css";

const ProgressBarSteps = (props) => {
    const {
        steps,
        currentStep,
        onStepClick,
    } = props;

    const handleOnStepClick = (index) => {
        if (!_.isFunction(onStepClick)) return;
        
        onStepClick(index);
    };

    const renderStep = (step, index) => {
        let title = step;

        if (!_.isString(step)) {
            title = step.title;
        }

        const isCurrent = index === currentStep;

        let stepClassName = 'pp-progress-bar-step';

        if (isCurrent) {
            stepClassName = `${stepClassName} current`;
        }

        if (index < currentStep) {
            stepClassName = `${stepClassName} selectable`;
        }

        return (
            <li
                key={title}
                className={stepClassName}
                onClick={ () => handleOnStepClick(index) }
            >
                <span>{ title }</span>
            </li>
        );
    };

    return (
        <ul className="pp-progress-bar-steps">
            {
                steps.map(renderStep)
            }
        </ul>
    );
};

const ProgressBar = (props) => {
    const {
        steps,
        currentStep,
        onStepChange,
        prevDisabled,
        nextDisabled,
        prevVisible,
        nextVisible,
    } = props;

    const handlePreviousButtonClick = () => {
        if (currentStep === 0) return;

        onStepChange(currentStep - 1);
    };

    const handleNextButtonClick = () => {
        if (currentStep + 1 === steps.length) return;

        onStepChange(currentStep + 1);
    };

    const handleOnStepClick = (index) => {
        if (currentStep <= index) return;

        onStepChange(index);
    };

    const renderPreviousButton = () => {
        if (!prevVisible) return null;

        return (
            <Col>
                <SecondaryButton
                    onClick={handlePreviousButtonClick}
                    disabled={prevDisabled}
                >
                    Anterior
                </SecondaryButton>
            </Col>
        );
    };

    const renderNextButton = () => {
        if (!nextVisible) return null;

        return (
            <Col>
                <PrimaryButton
                    onClick={handleNextButtonClick}
                    disabled={nextDisabled}
                >
                    Siguiente
                </PrimaryButton>
            </Col>
        );
    };

    const renderProgressBar = () => {
        return (
            <ProgressBarSteps
                steps={steps}
                currentStep={currentStep}
                onStepClick={handleOnStepClick}
            />
        );
    };

    return (
        <div className="pp-progress-bar">
            { renderProgressBar() }

            <Row className="pp-progress-bar-buttons">
                { renderPreviousButton() }
                { renderNextButton() }
            </Row>
        </div>
    );
};

ProgressBar.propTypes = {
    steps: PropTypes.arrayOf(PropTypes.oneOf([
        PropTypes.string,
        PropTypes.shape({
            title: PropTypes.string,
        }),
    ])),
    currentStep: PropTypes.number,
    onStepChange: PropTypes.func,
    prevDisabled: PropTypes.bool,
    nextDisabled: PropTypes.bool,
    prevVisible: PropTypes.bool,
    nextVisible: PropTypes.bool,
};

ProgressBar.defaultProps = {
    steps: [],
    currentStep: 0,
    onStepChange: () => {},
    prevDisabled: false,
    nextDisabled: false,
    prevVisible: true,
    nextVisible: true,
};

export default ProgressBar;
