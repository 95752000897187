import React, { useState } from "react";
import { Link } from "react-router-dom";
import _ from 'lodash';
import {
    Col,
    Container,
    Row,
} from "react-bootstrap";

import PrimaryButton from "../components/primary_button";

import MailIcon from '../assets/img/mail.png';
import WarningIcon from '../assets/img/warning.png';

import './review_section.css';

const { REACT_APP_SLACK_URL } = process.env;

const QUESTION_FORM_FIELDS = () => {
    return [
        {
            tag: 'textarea',
            className: "form-control",
            type: "text",
            name: "question",
            placeholder: 'Hola! Necesito mas información...',
            label: '¿Cómo te podemos ayudar?',
            validate: (value) => {
                if (_.isString(value) && value.length > 5) return null;

                return 'Necesitamos una pregunta para poder responderte';
            },
        },
        {
            tag: 'input',
            className: "form-control",
            type: "text",
            name: "name",
            placeholder: 'María González',
            label: '¿Cómo te llamas?',
            validate: (value) => {
                if (_.isString(value) && value.length > 5) return null;

                return 'Debes ingresar tu nombre';
            },
        },
        {
            tag: 'input',
            className: "form-control",
            type: "text",
            name: "email",
            placeholder: 'mgonzalez@tooto.cl',
            label: '¿A cuál correo te respondemos?',
            validate: (value) => {
                if (_.isString(value) && value.match(/^.{3,}@.{3,}\..{2,}$/) !== null) return null;

                return 'Debes ingresar un email válido';
            },
        },
    ];
};

const SUBMIT_FORM_FIELDS = () => {
    return [
        {
            tag: 'select',
            className: "form-control",
            type: "text",
            name: "period",
            label: '¿Cuánto tiempo te gustaría suscribirte?',
            options: [
                '1 mes',
                '6 meses',
                '12 meses',
                '18 meses',
                '24 meses',
            ],
            validate: (value) => {
                if (value.includes('mes')) return null;

                return 'Debes seleccionar una opción';
            },
        },
        {
            tag: 'input',
            className: "form-control",
            type: "text",
            name: "name",
            placeholder: 'María González',
            label: '¿Cómo te llamas?',
            validate: (value) => {
                if (_.isString(value) && value.length > 5) return null;

                return 'Debes ingresar tu nombre';
            },
        },
        {
            tag: 'input',
            className: "form-control",
            type: "text",
            name: "email",
            placeholder: 'mgonzalez@tooto.cl',
            label: '¿A cuál correo te contactamos?',
            validate: (value) => {
                if (_.isString(value) && value.match(/^.{3,}@.{3,}\..{2,}$/) !== null) return null;

                return 'Debes ingresar un email válido';
            },
        },
    ];
};

const renderSuccessMessage = () => {
    return (
        <div className="result-message">
            <img src={MailIcon} alt="Sent successfully" />
            <h5>
                ¡Buenísimo! Te contactaremos lo antes posible
            </h5>
            <br />
            <Link to='/success' style={{ fontSize: '1rem' }}>
                <PrimaryButton>
                    Volver al inicio
                </PrimaryButton>
            </Link>
        </div>
    );
};

const renderErrorMessage = () => {
    return (
        <div className="result-message">
            <img src={WarningIcon} alt="Sent failed" />
            <h5>
                ¡En algo nos caímos! Vuelve a intentarlo en un rato
            </h5>
            <br />
            <Link to='/success' style={{ fontSize: '1rem' }}>
                <PrimaryButton>
                    Volver al inicio
                </PrimaryButton>
            </Link>
        </div>
    );
};

const ReviewSection = (props) => {
    const {
        items,
    } = props;

    const [ formState, setFormState ] = useState({ items: items.map(item => ({ ...item, src: null })) });
    const [ formErrors, setFormErrors ] = useState({});
    const [ formStatus, setFormStatus ] = useState('pending');

    const handleOnChange = (event) => {
        const { name, value } = event.target;

        setFormState({
            ...formState,
            [name]: value,
        });
    };

    const handleOnBlur = (event) => {
        const { name, value } = event.target;
        
        let fields = SUBMIT_FORM_FIELDS();
        if (items.length === 0) {
            fields = QUESTION_FORM_FIELDS();
        }

        const field = fields.find((field) => field.name === name);
        if (_.isEmpty(field)) return null;

        const error = field.validate(value);
        setFormErrors({
            ...formErrors,
            [name]: error,
        });
    }

    const handleOnSubmit = () => {
        const hasErrors = Object.values(formErrors).filter(error => !_.isEmpty(error)).length;

        if (hasErrors) {
            alert('Necesitamos que completes el formulario');
        } else {
            
            const data = { text: JSON.stringify(formState, null, 2) };

            const request = new XMLHttpRequest();

            request.onreadystatechange = () => {
                if(request.readyState === XMLHttpRequest.DONE) {
                    const status = request.status;

                    if (status === 0 || (status >= 200 && status < 400)) {
                      setFormStatus('success');
                    } else {
                      setFormStatus('error');
                    }
                }
            };

            request.open("POST", REACT_APP_SLACK_URL);
            request.send(JSON.stringify(data));
        }
    };

    const renderField = (field) => {
        const { tag, label, placeholder, className, type, name, options } = field;

        const emptyValue = '';

        let value = _.isUndefined(formState[name]) ? emptyValue : formState[name];
        const error = formErrors[name];

        if (tag === 'textarea') {
            return (
                <div
                    className="form-group"
                    key={`form-field-${name}`}
                >
                    <p className="review-section-form-label">
                        { label }
                    </p>
                    <textarea
                        className={className}
                        name={name}
                        placeholder={placeholder}
                        value={value}
                        onChange={handleOnChange}
                        onBlur={handleOnBlur}
                    />
                    {
                        error && <p className="form-error">{error}</p>
                    }
                </div>
            );
        }

        if (tag === 'select') {
            if (value === emptyValue) {
                value = 'option-placeholder';
            }

            return (
                <div
                    className="form-group"
                    key={`form-field-${name}`}
                >
                    <p className="review-section-form-label">
                        { label }
                    </p>
                    <select
                        className={className}
                        name={name}
                        placeholder={placeholder}
                        value={value}
                        onChange={handleOnChange}
                        onBlur={handleOnBlur}
                    >
                        <option disabled value="option-placeholder">Selecciona una opción</option>
                        {
                            options.map((value) => {
                                return (
                                    <option
                                        key={value}
                                    >
                                        {value}
                                    </option>
                                );
                            })
                        }
                    </select>
                    {
                        error && <p className="form-error">{error}</p>
                    }
                </div>
            )
        }

        return (
            <div
                className="form-group"
                key={`form-field-${name}`}
            >
                <p className="review-section-form-label">
                    { label }
                </p>
                <input
                    className={className}
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                />
                {
                    error && <p className="form-error">{error}</p>
                }
            </div>
        );
    };

    const renderItem = (item) => {
        const { title, subtitle, src } = item;

        return (
            <div
                className="review-item-container"
                key={title}
            >
                <div className="review-item-image-container">
                    <img src={src} alt={title} />
                </div>
                <div className="review-item-text-container">
                    <div>
                        <p className="review-item-text-title">
                            {title}
                        </p>
                        {
                            subtitle && (
                                <p className="review-item-text-subtitle">
                                    { subtitle }
                                </p>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    };

    if (items.length === 0) {
        return (
            <Container className="review-section-container">
                <Row>
                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                        <h2 className="review-section-title">
                            ¿Estás vitrineando nomas?
                        </h2>
                        <p className="review-section-subtitle">
                            A veces cuesta probar cosas nuevas, te invitamos a que nos hagas todas las preguntas que quieras
                        </p>
                        {
                            formStatus === 'pending' && (
                                <>
                                    { QUESTION_FORM_FIELDS().map(renderField) }
                                    <br />
                                    <PrimaryButton
                                        block
                                        onClick={handleOnSubmit}
                                    >
                                        Quiero saber más
                                    </PrimaryButton>
                                </>
                            )
                        }
                        { formStatus === 'success' && renderSuccessMessage() }
                        { formStatus === 'error' && renderErrorMessage() }
                    </Col>
                </Row>
            </Container>
        )
    }

    return (
        <Container className="review-section-container">
            <Row>
                <Col xs={12} md={{ span: 6, offset: 3 }}>
                    <h2 className="review-section-title">
                        Revisa tu suscripción
                    </h2>
                    <p className="review-section-subtitle">
                        Recuerda que te enviaremos un correo antes de cada mes para que actualices e incluyas lo que necesitas. Mensualmente recibirás los siguientes artículos...
                    </p>
                    { items.map(renderItem) }
                </Col>
            </Row>
            <br />
            <br />
            <Row>
                <Col xs={12} md={{ span: 6, offset: 3 }}>
                    <h2 className="review-section-title">
                        Registra tus datos
                    </h2>
                    <p className="review-section-subtitle">
                        Deja tus datos y te contactaremos lo antes posible
                    </p>
                    {
                        formStatus === 'pending' && (
                            <>
                                { SUBMIT_FORM_FIELDS().map(renderField) }
                                <br />
                                <br />
                                <PrimaryButton
                                    block
                                    onClick={handleOnSubmit}
                                >
                                    Enviar solicitud
                                </PrimaryButton>
                                <br />
                                <br />
                                <p className="review-section-subtitle">
                                    Una vez aprobada tu suscripción, te enviaremos un mail con los detalles de pago
                                </p>
                            </>
                        )
                    }
                    { formStatus === 'success' && renderSuccessMessage() }
                    { formStatus === 'error' && renderErrorMessage() }
                </Col>
            </Row>
        </Container>
    );
};

export default ReviewSection;
