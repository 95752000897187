import React from "react";
import {
    Container,
} from "react-bootstrap";

import LogoHorizontal from '../assets/img/logos/logo_horizontal.svg';

import './footer_section.css';

const FooterSection = () => {

    return (
        <Container className="footer-container">
            <hr />
            <div className="footer-logo">
                <img src={LogoHorizontal} alt="tooto logo" />
            </div>
        </Container>
    );
};

export default FooterSection;
