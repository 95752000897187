import React from "react";
import PropTypes from 'prop-types';

import './primary_button.css';

const PrimaryButton = (props) => {
    const {
        block
    } = props;

    const style = {...props.style};
    const originalProps = { ...props };
    if (block) {
        style.width = '100%';
    }
    
    delete originalProps.block;
    
    return (
        <button
            className="pp-button"
            {...originalProps}
            style={style}
        >
            { props.children }
        </button>
    )
};

PrimaryButton.propTypes = {
    block: PropTypes.bool,
};

PrimaryButton.defaultProps = {
    block: false,
};

export default PrimaryButton;
