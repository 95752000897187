import React from "react";
import PropTypes from 'prop-types';
import {
    Container,
    Row,
    Col,
} from "react-bootstrap";

import './image_section.css';

const ImageSection = (props) => {
    const {
        children,
        src,
        alignment,
        backgroundSize,
    } = props;

    const imageOrder = alignment === 'left' ? 1 : 0;
    const textOrder = alignment === 'left' ? 0 : 1;

    const renderImage = () => {
        if (backgroundSize === 'contain') {
            return (
                <Col
                    xs={12}
                    md={{ order: imageOrder, span: 6 }}
                    className="image-section-img-container"
                >
                    <img src={src} alt="" />
                </Col>
            );
        }

        return (
            <Col
                xs={12}
                md={{ order: imageOrder, span: 6 }}
                style={{ backgroundImage: `Url(${src})`, backgroundSize }}
                className="image-section-img"
            />
        )
    }

    return (
        <div className="image-section">
            <Container>
                <Row>
                    { renderImage() }
                    <Col
                        xs={12}
                        md={{ order: textOrder, span: 6 }}
                        className="image-section-text-container"
                    >
                        { children }
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

ImageSection.propTypes = {
    title: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
    children: PropTypes.node,
    src: PropTypes.string,
    alignment: PropTypes.oneOf(['left', 'right']),
    backgroundSize: PropTypes.oneOf(['cover', 'contain']),
};

ImageSection.defaultProps = {
    children: null,
    src: '',
    alignment: 'left',
    backgroundSize: 'cover',
}

export default ImageSection;
