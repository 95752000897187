import React from 'react';
import PropTypes from 'prop-types';
import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';

import './hero_section_2.css';

const HeroSection2 = (props) => {
    const {
        title,
        subtitle,
        src,
        children,
        alignment,
    } = props;

    const textOffset = alignment === 'left' ? 0 : 6;

    const renderChildren = () => {
        if (children === null) return null;

        return (
            <div className="hero-children">
                { children }
            </div>
        );
    };

    return (
        <div className="hero-section" style={{ backgroundImage: `url(${src})` }}>
            <Container>
                <Row className="hero-section-row">
                    <Col xs={12} md={{ offset: textOffset, span: 6 }} className="hero-section-col">
                        <div className="hero-section-text-container">
                            <h1 className="hero-title">
                                { title }
                            </h1>
                            <p className="hero-subtitle">
                                { subtitle }
                            </p>
                            { renderChildren() }
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

HeroSection2.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    src: PropTypes.string,
    children: PropTypes.node,
    alignment: PropTypes.oneOf(['left', 'right']),
};

HeroSection2.defaultProps = {
    subtitle: null,
    src: '',
    children: null,
    alignment: 'left',
}

export default HeroSection2;
